@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --laburo-green : #15BC7F;
}

.video-default-bg {
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
background-image: url('../../assets/videos/videoPoster.png');
z-index:-21;
background-size: cover;
  } 


  .video-controls{
    pointer-events: none;
  }


.bg-laburo-green {
    background-color:var(--laburo-green);
}
.bg-laburo-gray {
background-color: rgba(80, 80, 80, 0.1)

}
.laburo-green{
    color: var(--laburo-green);
}
.laburo-gray{
    color: rgba(80, 80, 80, 0.1)

}



.flx{
    display: flex;
}

.flx-center{
 
    justify-content: center ;
    align-items: center;
}

.flx-col {
    display: flex;
    flex-direction: column;
}

.space-btwn{
    justify-content: space-between;
}

.space-even{
    justify-content: space-evenly;
}


.txt-s1{
    font-size: 10px;
}

.txt-s2{
    font-size: 12px;
}

.txt-s3{
    font-size: 16px;
}

.txt-s4{
    font-size: 20px;
}
.txt-s5{
    font-size: 30px;
}
.txt-s6{
    font-size: 32px;
}


.mb-50{
    margin-bottom: 50px;
}

.mt-50{
    margin-top: 50px;
}

.mb-25{
    margin-bottom: 25px;
}

.mt-25{
    margin-top: 25px;
}


.link-style{
    text-decoration: none;
    color: black;
}

.txt-white{
    color:white
}

.thank-you-txt-more-jobs{
    margin-top: 20px;
    font-size: 18px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media(max-width:750px){

    .thank-you-txt-title{

   width: 100%!important;
   text-align: center;

    }

    
}








.in-dev{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    

}

.w100{
    width: 100%;
}



.hide{
    display: none !important;
}
.show{
    display: block !important ;
}

.desktop-only{
    display: none;
}

.skip-navbar-margin{
    padding-top: 75px;
}

.desktop-only-flex{
    display: none;
}

/*  mobile */


@media(min-width:750px){
 
    .mobile-only{
        display: none !important;
    }

    .desktop-only{
        display: block;
    }

    .desktop-only-flex{
        display: flex;
    }




  }






.button{
    background-color: transparent;
font-size: inherit;

}

.button:hover{
    cursor: pointer;
}



/* dropdown */


 .dropdown-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center ;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px 10px;
    
 }

 .dropdown-content{
    margin-top: 10px;
    padding: 15px 10px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
   
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 180px;

  
 }

 .drop-down-element:hover{
    text-decoration: underline;

cursor: pointer;


 }







 .dropdown-popup-container{
   left: 0;
   top: 0;
    position:fixed;
    background-color: rgba(0, 0, 0, 0.2);

    height  :100%;
    width: 100%;
    padding-top: 150px;
    z-index: 1000;
display: flex;
justify-content: center;
}

.dropdown-popup-content{
background-color: white;
border-radius: 10px;
height: 100%;
width: 100%;
padding: 10px 10px;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 10px;
justify-content: space-between;

}
.dropdown-popup-divider{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    height: 1px;
    margin-top: 10px;
    margin-bottom: 20px;

}

.dropdown-popup-items{
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    width: 100%;
    gap: 20px;
    font-size: 20px;

}


.dropdown-popup-button{
    background-color: var(--laburo-green);
    color:white;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 20px;
    width: 100%;
}


.dropdown-popup-button:hover{
    cursor: pointer;
}

 .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for other browsers like Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;     /* Firefox */
  }

  .no-border{
    border: none !important;
  }

  .cursor-pointer:hover
  {cursor: pointer;
}


@media(min-width:750px){
    .filter-popup-container{
        position: absolute !important;
        
        height: 100% !important;
        width: 100%;
        padding-top: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        background-color: transparent;
        border: red 1px solid;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .dropdown-popup-content{
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px;
        height: auto;
        max-width: 500px;
        
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .dropdown-popup-button{
        position: relative;
        width: 100%;
        margin-top: 50px;
    }
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }
  
  /* For Firefox */
  input[type="color"]::-moz-color-swatch {
    border: none;
    border-radius: 50%;
  }


