*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Alatsi', sans-serif;
   


}

.lexend-title {
  font-family: "Lexend", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.lexend-subtitle {
  font-family: "Lexend", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
